import React from "react";
import { Tooltip } from "@chakra-ui/react";
import "./index.scss";
import Love from "../../Assets/love.png";
import Mail from "../../Assets/mail.svg";
import Linkedin from "../../Assets/linkedin.svg";
import Github from "../../Assets/github.svg";

function Contact() {
  return (
    <div className="contact">
      <div className="contact-container">
        <img src={Love} alt="Love illustration" />
        <div className="social">
          <p className="connect">Let's connect!</p>
          <div className="container social">
            <ul className="social-list">
              <Tooltip label="Email" placement="bottom">
                <li>
                  <a
                    href="mailto:ghayda.rafa@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="social-logo" src={Mail} alt="mail" />
                  </a>
                </li>
              </Tooltip>

              <Tooltip label="Linkedin" placement="bottom">
                <li>
                  <a
                    href="https://www.linkedin.com/in/ghaydarafa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-logo"
                      src={Linkedin}
                      alt="linkedin"
                    />
                  </a>
                </li>
              </Tooltip>

              <Tooltip label="Github" placement="bottom">
                <li>
                  <a
                    href="https://github.com/ghaydarafa"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="social-logo" src={Github} alt="github" />
                  </a>
                </li>
              </Tooltip>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
