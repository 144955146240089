import React from "react";
import "./index.scss";
import InterestCard from "../InterestCard";
import Term from "../../Assets/3rd.svg";
import Location from "../../Assets/id.svg";
import Frontend from "../../Assets/frontend.svg";
import Backend from "../../Assets/backend.svg";
import ProductDesign from "../../Assets/product-design.svg";

function AboutMe() {
  return (
    <div className="about">
      <div className="title">About me</div>
      <div className="info">
        <div className="term">
          <img src={Term} alt="3rd year Computer Science student" />
        </div>
        <div className="location">
          <img src={Location} alt="Currently located in Jakarta, Indonesia" />
        </div>
      </div>
      <div className="interest">
        <div className="interest__text">I'm interested in</div>
        <div className="interest__cards">
          <InterestCard
            src={Frontend}
            alt="Frontend illustration"
            heading="Frontend"
          />
          <InterestCard
            src={Backend}
            alt="Backend illustration"
            heading="Backend"
          />
          <InterestCard
            src={ProductDesign}
            alt="Product Design illustration"
            heading="Product Design"
          />
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
