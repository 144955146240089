import React from "react";
import "./index.scss";
import ProjectCard from "../ProjectCard";
import PersonalWeb from "../../Assets/personal-web.png";
import PetDaycare from "../../Assets/petdaycare.png";
import Babadu from "../../Assets/babadu.png";
import Pedulee from "../../Assets/pedulee.png";
import Todolist from "../../Assets/todolist.png";
import Ecommerce from "../../Assets/ecommerce.png";
import Skillboozt from "../../Assets/skillboozt.png";
import ReactLogo from "../../Assets/react.svg";
import VueLogo from "../../Assets/vue.svg";
import HTMLLogo from "../../Assets/html.svg";
import CSSLogo from "../../Assets/css.svg";
import JavascriptLogo from "../../Assets/javascript.svg";
import SpringbootLogo from "../../Assets/springboot.svg";
import DjangoLogo from "../../Assets/django.svg";
import JavaLogo from "../../Assets/java.svg";
import PythonLogo from "../../Assets/python.svg";
import PostgresqlLogo from "../../Assets/postgresql.svg";
import FlutterLogo from "../../Assets/flutter.svg";
import DartLogo from "../../Assets/dart.svg";
import FigmaLogo from "../../Assets/figma.svg";

function Projects() {
  const projects = [
    {
      imageSrc: PersonalWeb,
      title: "Personal Website",
      description: "My latest project for displaying all my portfolios.",
      technologies: [
        { name: "React", logo: ReactLogo },
        { name: "HTML", logo: HTMLLogo },
        { name: "CSS", logo: CSSLogo },
        { name: "Javascript", logo: JavascriptLogo },
      ],
      buttons: [],
    },
    {
      imageSrc: PetDaycare,
      title: "Pet Daycare",
      description:
        "A website application for online pet care reservations. There are USER and ADMIN roles which will have their own dashboard.",
      technologies: [
        { name: "Spring Boot", logo: SpringbootLogo },
        { name: "Java", logo: JavaLogo },
        { name: "React", logo: ReactLogo },
      ],
      buttons: [],
    },
    {
      imageSrc: Babadu,
      title: "BABADU",
      description:
        "A World Badminton Tour Information System developed using Django with PostgreSQL as the database and utilized psycopg2 for data manipulation.",
      technologies: [
        { name: "Python", logo: PythonLogo },
        { name: "Django", logo: DjangoLogo },
        { name: "PostgreSQL", logo: PostgresqlLogo },
        { name: "HTML", logo: HTMLLogo },
        { name: "CSS", logo: CSSLogo },
        { name: "Javascript", logo: JavascriptLogo },
      ],
      buttons: [
        { text: "View Repository", link: "https://github.com/Basdat-1/babadu" },
      ],
    },
    {
      imageSrc: Pedulee,
      title: "PEDULEE",
      description:
        "A platform that accepts donations in various forms to address humanitarian issues related to the G20. Implemented in both website and mobile version.",
      technologies: [
        { name: "Python", logo: PythonLogo },
        { name: "Django", logo: DjangoLogo },
        { name: "PostgreSQL", logo: PostgresqlLogo },
        { name: "HTML", logo: HTMLLogo },
        { name: "CSS", logo: CSSLogo },
        { name: "Javascript", logo: JavascriptLogo },
        { name: "Flutter", logo: FlutterLogo },
        { name: "Dart", logo: DartLogo },
      ],
      buttons: [
        {
          text: "View Web Version Repository",
          link: "https://github.com/pbp-b-10/project-b10",
        },
        {
          text: "View Mobile Version Repository",
          link: "https://github.com/pbp-b-10/pedulee-mobile",
        },
      ],
    },
    {
      imageSrc: Todolist,
      title: "TODOLIST",
      description:
        "A simple web-based todo list application that allows users to manage their tasks efficiently. Some features of the application are task creation, deletion, marking as done or undone, and user registration and login.",
      technologies: [
        { name: "Python", logo: PythonLogo },
        { name: "Django", logo: DjangoLogo },
        { name: "PostgreSQL", logo: PostgresqlLogo },
        { name: "HTML", logo: HTMLLogo },
        { name: "CSS", logo: CSSLogo },
        { name: "Javascript", logo: JavascriptLogo },
      ],
      buttons: [
        {
          text: "View Repository",
          link: "https://github.com/ghaydarafa/tugas-2-pbp",
        },
      ],
    },
    {
      imageSrc: Ecommerce,
      title: "One-Page Ecommerce Catalog",
      description:
        "A one-page web that allows users to navigate through different product categories in a single-page interface. The project showcases efficient API fetching, dynamic page design, and an intuitive user experience.",
      technologies: [
        { name: "VueJS", logo: VueLogo },
        { name: "HTML", logo: HTMLLogo },
        { name: "CSS", logo: CSSLogo },
        { name: "Javascript", logo: JavascriptLogo },
      ],
      buttons: [
        {
          text: "View Repository",
          link: "https://github.com/ghaydarafa/ecommerce-catalog",
        },
      ],
    },
    {
      imageSrc: Skillboozt,
      title: "SKILLBOOZT",
      description:
        "An e-learning soft skills mobile application. The application features two user interfaces, one for students and one for mentors, each equipped with supporting functionalities. The app offers three main features: video lessons with comments features, consultations with mentors for personalized guidance, and quizzes or assessments to help learners evaluate their progress.",
      technologies: [
        { name: "Figma", logo: FigmaLogo },
      ],
      buttons: [
        {
          text: "Learner's Prototype",
          link: "http://bit.ly/SkillBoozt-Pelajar",
        },
        {
          text: "Mentor's Prototype",
          link: "http://bit.ly/SkillBoozt-Pengajar",
        },
        {
          text: "View Project Presentation",
          link: "https://www.canva.com/design/DAFlYo9JMMc/GjsIxtBi_o5X7HtIn4PgvQ/view?utm_content=DAFlYo9JMMc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
        },
      ],
    },
  ];

  return (
    <div className="projects">
      <div className="title">My Projects</div>
      <div className="projects__cards">
        {projects.map((project, index) => (
          <ProjectCard key={index} {...project} />
        ))}
      </div>
    </div>
  );
}

export default Projects;
