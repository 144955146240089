import React from "react";
import { Card, CardBody, Image, Heading } from "@chakra-ui/react";
import "./index.scss";

const InterestCard = ({ src, alt, heading }) => (
  <Card
    maxW="sm"
    justifyContent="center"
    alignItems="center"
    boxShadow="lg"
    backgroundColor="#ff9595"
    color="white"
    className="interest_card"
  >
    <CardBody>
      <div className="interest_card_image_container">
        <Image
          src={src}
          alt={alt}
          borderRadius="md"
          className="interest_card_image"
        />
      </div>
      <Heading size="md">{heading}</Heading>
    </CardBody>
  </Card>
);

export default InterestCard;
