import * as React from "react";
import "./App.css";
import Navbar from "./Components/Navbar";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "./Components/Home";
import AboutMe from "./Components/AboutMe";
import Projects from "./Components/Projects";
import Contact from "./Components/Contact";

function App() {
  return (
    <ChakraProvider>
      <Router>
        <div className="App">
          <Navbar />

          <div id="home">
            <Home />
          </div>
          <div id="about-me">
            <AboutMe />
          </div>
          <div id="projects">
            <Projects />
          </div>
          <div id="contact">
            <Contact />
          </div>
        </div>
      </Router>
    </ChakraProvider>
  );
}

export default App;
