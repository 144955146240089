import React from "react";
import "./index.scss";
import Laptop from "../../Assets/laptop.svg";
import Resume from "../../Assets/Resume.pdf";
import { Button } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";

function Home() {
  return (
    <div className="home">
      <div className="intro">
        <div className="intro__hi">Hi! I'm Rafa</div>
        <div className="intro__desc">
          Computer Science student at Universitas Indonesia with a passion for
          exploring new technologies and expanding my knowledge.
        </div>
        <a href={Resume} download="Ghayda Rafa Hernawan - CV">
          <Button
            className="cv_btn"
            leftIcon={<DownloadIcon />}
            colorScheme="pink"
            color={"#ff9595"}
            outline={"1px solid #ff9595"}
            variant="outline"
          >
            Download CV
          </Button>
        </a>
      </div>

      <div className="laptop">
        <img className="laptop__img" src={Laptop} alt="illustration" />
      </div>
    </div>
  );
}

export default Home;
