import React, { useEffect, useState } from "react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import "./index.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    if (sectionId === "home") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      const section = document.getElementById(sectionId);
      section.scrollIntoView({ behavior: "smooth" });
    }
    setMenuOpen(false); // Close the menu after clicking a link
  };

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  const handleLinkClick = (e, sectionId) => {
    e.preventDefault();
    scrollToSection(e, sectionId);
    if (sectionId === 'home') {
      navigate(``);
    } else {
      navigate(`/${sectionId}`);
    }
  };

  return (
    <header className={`header ${isScrolled ? "header--scrolled" : ""}`}>
      <div className="header__content">
        <Link to="/" className="header__content__logo">
          GR
        </Link>
        <nav
          className={`${"header__content__nav"} ${
            menuOpen && size.width < 768 ? `${"isMenu"}` : ""
          }`}
        >
          <ul>
            <li>
              <Link
                to="/"
                onClick={(e) => handleLinkClick(e, "home")}
                className={location.pathname === "/" ? "active" : ""}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about-me"
                onClick={(e) => handleLinkClick(e, "about-me")}
                className={location.pathname === "/about-me" ? "active" : ""}
              >
                About me
              </Link>
            </li>
            <li>
              <Link
                to="/projects"
                onClick={(e) => handleLinkClick(e, "projects")}
                className={location.pathname === "/projects" ? "active" : ""}
              >
                Projects
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                onClick={(e) => handleLinkClick(e, "contact")}
                className={location.pathname === "/contact" ? "active" : ""}
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
        <div className="header__content__toggle">
          {!menuOpen ? (
            <HamburgerIcon onClick={menuToggleHandler} />
          ) : (
            <CloseIcon onClick={menuToggleHandler} />
          )}
        </div>
      </div>
    </header>
  );
}

export default Navbar;
