import React from "react";
import "./index.scss";

import {
  Card,
  CardBody,
  CardFooter,
  Image,
  Heading,
  Stack,
  Text,
  Button,
  Flex,
  Spacer,
} from "@chakra-ui/react";

function ProjectCard({
  imageSrc,
  title,
  description,
  technologies,
  buttons,
}) {
  return (
    <Card
      direction={{ base: "column", sm: "row" }}
      overflow="hidden"
      variant="outline"
      boxShadow={"lg"}
      className="project-card"
    >
      <Image
        objectFit="cover"
        maxW={{ base: "100%", sm: "340px" }}
        src={imageSrc}
        alt={title}
        className="project-card__image"
      />

      <Stack>
        <CardBody>
          <Heading size="md" textAlign={"left"} fontSize={"1.4rem"}>
            {title}
          </Heading>

          <Text textAlign={"left"} py="2" fontSize={"1.2rem"}>
            {description}
          </Text>

          <div className="technology">
            <ul className="tech-list">
              {technologies.map((tech) => (
                <li key={tech}>
                  <img className="tech-logo" src={tech.logo} alt={tech.name} />
                  <span className="tech-label">{tech.name}</span>
                </li>
              ))}
            </ul>
          </div>
        </CardBody>

        <CardFooter paddingTop={"0px"}>
          <div className="button-container">
          {buttons.map((button, index) => (
            <a
              key={index}
              href={button.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button marginRight={"1rem"}
                colorScheme="pink"
                color={"#ff9595"}
                outline={"1px solid #ff9595"}
                variant="outline"
              >
                {button.text}
              </Button>
            </a>
          ))}
          </div>
        </CardFooter>
      </Stack>
    </Card>
  );
}

export default ProjectCard;
